@use 'vars' as *;
@use 'banners';
@use 'contact';
@use 'products';
@use 'search';


html {
    color: $grey-text;
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
    letter-spacing: 1px;
}

*, *:before, *:after {
    box-sizing: inherit;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $cyan;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
    padding:0;
    margin:0;
    font-size:18px;
}

.contentwrap {
    max-width:1200px;
    margin: 0 auto;
    position:relative;
    padding: 30px;
}
header .contentwrap,
footer .contentwrap {
    padding: 0 20px;
}

img {
    border:none;
}

a.logo {
    display: inline-block;
    width: 250px;
    height:auto;
    font-size:3rem;
}

/** menu generic **/
nav ul {
    list-style:none;
    padding: 0;
    margin: 0;
}
nav li {
    display: inline-block;
    box-sizing: border-box;
}
nav a {
    display: block;
    color:inherit;
    text-decoration: none;
}
header a,
footer a,
.contact-details a {
    text-decoration: none;
    color: inherit;
}

/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
}
form p {
    position: relative;
    margin: 5px 0;
}
label.float,
input {
    height: 40px;
    line-height: 40px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-size: 16px;
    padding-top: 15px;
    width: 100%;
    box-sizing: border-box;
}
textarea {
    min-height: 80px;
}
label.float {
    position: absolute;
    left:0;
    text-transform: uppercase;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    padding-left: 0;
    line-height: 1;
    font-size: 0.75em;
}
form button {
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}

/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
}

/*============ header ============*/
header.top {
    font-size:16px;
}
.header-bar {
    color:#ffffff;
}
.header-logo{
   display: flex;
   justify-content: center;
   position: relative;
   background-color: $blue;
}

.header-phone{
    transform: skew(-30deg);
    color: #FFF;
    background-color: $cyan;
    position: absolute;
    padding:0 4rem;
    height: 100%;
    right:-20px;
    display: flex;
    align-items: center;
    h4 {
        color: inherit;
    }
    & > * {
        transform: skew(30deg);
    }
}

header.top ul {
    text-transform: uppercase;
}
header.top li {
    vertical-align:top;
}

.header-menu {
    font-weight: bolder;
    color: $blue;
    padding-top: 1rem;
    & > ul {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align:center;
        
    }
    li {
        padding:20px;
    }
   
    .depth-0.has-sub{
        .title{
            font-weight: bolder;
        }
        & > ul{
            display: none;
            // display:flex;
            justify-content: center;
            width: 100vw;
            position: absolute;
            left:0;
            margin-top: 1rem;
            padding-top:2rem;
            padding-bottom:4rem;
            background-color: $grey;
            & > li.depth-1.has-sub  {
                margin: 0 2rem;
                text-align: left;
                width: 24rem;

                & > a{
                    border-bottom:1px solid;
                    padding-bottom:1rem;
                    margin-bottom:1rem;
                    cursor: default;
                    pointer-events: none;
                }
                & > ul {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                
                    flex-wrap: wrap;
                    li{
                        margin:0;
                        width: 50%;
                        padding:.5rem 10px;
                        border-bottom:1px solid;
                        color:$white;
                       .title{
                            font-weight: bold;
                       }
                    }
                    li:nth-child(odd){
                        padding-left:0px;
                    }
                    li:nth-child(even){
                        padding-right:0px;
                    }
                        
                }
                
            }
            
        }
        &:hover, &:focus{
            ul{
                display: flex;
                z-index: 99;
            }
            & > a{
                position: relative;
                &::after{
                    content: "";
                    bottom: -25px;
                    left: 50%;
                    margin-left: -10px;
                    z-index: 9999;
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    transform: rotate(45deg);
                    background-color: $white;
                }
            }
    
        }    
    }
}

#mobile-menu {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    margin: 2px;
    margin-bottom: 0;
    cursor: pointer;
    z-index:100;
    &  > span {
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: #fff;
        margin: 6px 0;
        transition: all 0.5s ease;
    }
}
.search_input{
    display: inline-block;
    padding:0;
    margin:0;
    border:none;
    outline:none;
    font-family: inherit;
    font-weight: inherit;
    color:  $blue;
    &::placeholder{
        color:  inherit;
    }
}
/* Label Float */
/* ----------------- */

.form-field {
    position: relative;
    line-height: 1;
    width: inherit;

    & > * {
        
        transition: transform 200ms ease;
        background-color: transparent!important;
        color: INHERIT!important;
    }
    label{
        position: absolute;
        top: 50%;
        margin-top: -.5em;
        left:0;
        padding-left:inherit;
        pointer-events: none;
    }
}
.form-field textarea ~ label{
    top:0;
    margin-top: 1.5em;
}
.form-field input:focus ~ label,
.form-field textarea:focus ~ label,
.form-field label.freeze {
    font-size: 0.7em;
    font-weight: 400;
    /* Use "Translate" function instead of classic positioning for better performance */
    transform: translateY(-1.5em);
}

/* ----------------- */
/* End of Label Float */ 

.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}
.slide-menu-active #mobile-menu span.three {
     -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
    transform: rotate(45deg) translate(-6px, -6px) ;
}
.slide-menu-active #mobile-menu span.two {
    opacity: 0;
}

/***** Slide menu ****/
.slide-menu {
    display:none;
	background: $black;
    width:240px;
    z-index:1000;
	position: fixed;
	height: 100%;
	top: 0;
	right: -240px;
	overflow-y:scroll;
}
.slide-menu-active .slide-menu {
    right: 0px;
}
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    right:0;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu,
#product-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.slide-menu .menu-title {
	padding: 1em;
	color:$black;
	background:$white;
	margin:0;
	font-weight:bold;
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
}
.slide-menu ul ul {
	background:#999;
}
.slide-menu ul ul ul{
	background:#666;
}
.slide-menu li {
    position:relative;
    display: block;
}
.slide-menu li > ul {
    display:none;
}
.slide-menu li.expand-active > ul {
    display:block;
}
.slide-menu a{
	display: block;
	color: #fff;
	font-size: 16px;
	font-weight: 300;
	border-bottom: 1px solid #030b16;
	padding: 18px;
	text-decoration:none;
}
.slide-menu .form-field{
    color: #fff;
	font-size: 16px;
	font-weight: 300;
    padding: 18px;
}
.slide-menu li.has-sub {
    position: relative;
    display: block;
}
.slide-menu li.has-sub > a {
    position: relative;
    border-right: 1px solid #030b16;
    margin-right: 50px; /* space for drop */
}
.slide-menu a:hover {
	background: #258ecd;
}
.slide-menu li.has-sub > a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: #030b16;
    width: 50px;
    height: 1px; /* border bottom */
    z-index: 100;
}
.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #030b16;
    height: 54px;
    width: 50px;
    cursor: pointer;
}
.slide-menu ul .expand i {
    content:"";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.expand-active > .expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.slide-menu .depth-2 A[href=""]{
    display: none;
}
/*============ footer ============*/
footer .footer-bottom {
    position: relative;
    background-color: $black;
    color: $white;
    display:flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    padding-top: 0px;
    .logo {

        text-align: center;
        width: auto;
        font-size: 2rem;
        & > img{
            width:150px;
        }
    }
    .footer-menu > ul{
        z-index: 10;
        position: relative;
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
        align-items: center;
        font-weight: bold;
        padding: 0px;
        .depth-0{
            a {
                display: block;
                padding: 5px 0.9rem;
            }
        }
        .depth-1{
            display: none;
        }
        label {
            width: 100%;
            text-align: center;
        }
        .search_input {
            text-align: center;
        }
    }
    .footer-contact{
        > div{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .button {
            color: #FFF;
            border:1px solid $white;
            margin: 5px;
            &:hover {
                outline: 1px solid white;
            }
        }
    }
    .footer-product-image{
        position: absolute;
        width: 250px;
        bottom: 0;
        right: 4vw;
    }
}

/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: inherit;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}


/*============ default page============*/
/** content-header in _banners **/
.content-body {
    padding-top:3rem;
    padding-bottom: 4rem;
    .page-title{ /* size set in _banners */
        letter-spacing: 2px;
        margin-bottom:3rem;
    }
}
/*============ default page - content footer section ============*/
.footer-logos{
    text-align: center;
    font-size:1rem;
    color:$blue;
    text-transform: uppercase;
    padding-top: 30px;
}

.footer-logos-wrapper{
    background-color: $off-white;
    padding: 1rem 0;
    .banner-stage{
        // overflow: auto;
        width: 125vw;
        white-space: nowrap;
        .banner{
            width: 20vw;
            display: inline-block;
            margin: 5px;
            .banner-name,
            .banner-title,
            .banner-caption {
                display: none;
            }
        }
        .banner-inner-image {
            max-height: 100px;
        }
    }
}

/*============  Gallery Styling ============*/
.kmsgallery {
    .kmsgallery-thumbnail-image {
        transition: filter 0.3s ease;
        &:hover {
            filter: brightness(80%);
        }
    }
}


.kmsgallery.display-default{

    display: flex;
    max-height: 50vw;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
    
  
    & > * {
        flex: 1 1 auto;
        height: 24vw;
        margin: 0.5vw;
        width: initial;
    }      
    & > *:first-child{
        height: 49vw;
    }

    & > *:nth-last-child(1):nth-child(even){
        height: 49vw;
    }
}

.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}

/* ==========================================================================
   Thumbnail (Masonry Style)
   ========================================================================== */
.case-study-thumbnail{
    display: grid;
    grid-template-columns: 1fr 200px 1fr;
    @media only screen and (max-width: 960px) {
        grid-template-columns: 1fr 15vw 1fr;
    }
    @media only screen and (max-width: 576px) {
        grid-template-columns: 1fr;
        & > *{
            grid-column: auto!important;
        }
    }
    grid-gap: 1rem;
    font-size: 1rem;
    & > *{
        &:nth-child(4n+1){
            grid-column: 1 / 3;
        }
        &:nth-child(4n+4){
            grid-column: 2 / -1;
     
        }
        &:last-child:nth-child(odd){
            grid-column: 1 / -1;
        }
        img{
            transition: filter 0.3s ease;
            height: 25vw!important;
            @media only screen and (max-width: 960px) {
                height: 30vw!important;
            }
            @media only screen and (max-width: 600px) {
                height: 50vw!important;
            }
            width:100%;
            object-fit: cover;
        }
        a{
            display: block;
            text-decoration: none;
        }
        .title{
            text-transform: uppercase;
            color:$cyan;
            margin: 1em  0;
            transition: color 0.3s ease;
            @media only screen and (max-width: 1200px) {
                font-size: 4vw;
            }
        }
        &:hover {
            img { /* darker */
                filter: brightness(80%);
            }
            .title {
                color: $blue;
            }
        }
    }
}
.case-study-thumbnail.related{
    margin-top:4.5rem;
    margin-bottom: 1rem;
    grid-gap: 2rem;
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1200px) {
    footer .footer-bottom{
        .footer-product-image{
            right: 0;
            width: 200px;
        }
    }
}

@media only screen and (max-width: 960px) {
    #mobile-menu {
        display:block;
    }
    .header-phone{
        padding-right: 7rem;
        padding-left:2rem;
    }
    .header-menu,
    .main-menu {
        display:none;
    }
    .slide-menu {
        display:block;
    }
    .slide-menu-active .site-content-menu-slide {
        right: 240px;
    }
    .header-logo{
        justify-content:flex-start;
        padding: 0 1rem;
        padding-right: 4rem;
    }
    footer .footer-bottom{
        .footer-product-image{
            display: none;
        }
    }
}

@media only screen and (max-width: 760px) {
    a {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
    }
    .content-body {
        padding: 20px 0px;
    }
    .footer-logos-wrapper {
        padding: 10px 0px;
    }
}
@media only screen and (max-width: 600px) {
    .contentwrap {
        padding: 20px;
    }
    .header-phone {
        display: none;
    }
/** gallery stack **/
    .kmsgallery.display-default {
        display: block;
        width: 100%;
        max-height: none;
        height: auto;
        > a {
            display: block;
            height: auto;
            padding-top: 60%;
            width: 100%;
            margin: 5px 0px;
            &:first-of-type {
                width: 100%;
                padding-top: 60%;
                height: auto;
            }
        }
    }
    .t-case-study-item {
        .content-body {
            padding-bottom: 0px;
            .contentwrap {
                &:first-of-type {
                    padding-bottom: 0px;
                }
            }
        }
    }
    .case-study-thumbnail.related {
        margin-top: 40px;
    }
/** footer **/
    .footer-contact {
        .button > span {
            display: none;
        }
    }
}

/* ==========================================================================
   Helper classes
   ========================================================================== */
.slim{
    margin: 0 !important;
    padding: 0 !important;
}
.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

