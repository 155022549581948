@import 'vars';

a.result {
    color: inherit;
    text-decoration: none;
    h2 {
        color: $cyan;
        margin-bottom: 0px;
    }
    small {
        color: #bbbbbb;
    }
}
.match {
    color: $blue;
    font-weight: bold;
}