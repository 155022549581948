@use 'vars' as *;

.t-products{
    .product_container{
        display: flex;
        margin-bottom: 4rem;
        .menu{
            width:30%;
            display: flex;
            flex-direction: column;
            padding-right: 3.5rem;
            .collapsible{
                .with-arrow{
                    position: relative;
                }
                .with-arrow::after{
                    content:"";
                    position:absolute;
                    top:50%;
                    margin-top: -5px;
                    right:10px;
                    width: 0; 
                    height: 0; 
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid $cyan;
                    transition: all 0.5s ease-out;
                }

                &.active{
                    > .with-arrow::after {
                        transform: rotate(180deg);
                        transition: all 0.5s ease-out;
                    }
                }
            }
            

            .title{
                color:$cyan;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 2rem;
                margin:0;
                padding:0;
                padding-top:1rem;
                padding-right: 26px;
                border-bottom: solid 1px rgba(1,1,1,.1);
                position: relative;
               
            }
           
            .collapsible{
                
                margin:0;
                font-size: 1rem;
                color: $black;
                list-style: none;
                text-transform: uppercase;
                font-weight: bold;
                padding-left:0;
                @media screen and (max-width: 760px){
                    font-size: 14px;
                }
                @media screen and (max-width: 576px){
                    font-size: 12px;
                }
                a{
                    text-decoration: none;
                }
            }
            .collapsible > ul {
                max-height: 0;
                transition: max-height 0.5s ease-out;
                list-style:none;
                overflow: hidden;
                padding-left:0;
                li{
                    padding:.5rem 0;
                    padding-left:10px;
                    padding-right: 30px;
                }
            }
            .level-0 > .leaf > li{
                padding:.5rem;
                padding-left:20px;
                border-bottom: solid 1px rgba(1,1,1,.1);
                & > a{
                    color:$black;
                }
                
                &:hover > a{
                    color:$cyan;
                }
                &:last-child{
                    border-bottom: none;
                }
            }

            .level-0.active > li, .level-0:hover > li
            {
                color:$white;
                background-color: $cyan;
                &.with-arrow::after{
                    border-bottom-color: $white;
                }
            }
           
            .leaf > li{
                    padding:.5rem;
                    padding-left:20px;
                }
            .level-1{
                &.active > li , &:hover > li
                {
                    color:$cyan;
                    border-bottom: solid 1px rgba(1,1,1,.1)
                }
                .product_filter{
                    cursor: pointer;
                    display:block;
                    &.active{
                        color:$cyan;
                    }
                }
                
            }
            //.collapsible:hover > ul, 
            .collapsible.active > ul{
                max-height: 500px;
                transition: max-height 0.5s ease-out;
                border-bottom: solid 1px rgba(1,1,1,.1);
            }
           
        }
        .details{
            width:70%;
            display: flex;
            flex-direction: column;
            .header{
                margin-bottom: 3rem;
                @media screen and (max-width: 760px){
                    margin-bottom: 0;
                }
                .title{
                    text-transform: uppercase;
                    color: $cyan;
                    font-size: 2rem;
                    margin-bottom:1rem;
                }
                .summary{
                    margin-top:.5rem;
                    font-size: 1rem;
                    display: flex;
                    .loading{
                        margin:auto;
                    }
                }
            }
            .body{
                display:grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 3rem;
                .product-item{
                    .image-wrapper{
                        position: relative;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        &::before { /* responsive square */
                            content:"";
                            position: static;
                            display: block;
                            width: 100%;
                            padding-top: 100%;
                            background-color: transparent;
                            transition: background-color 0.3s ease;
                        }
                        &:hover::before {
                            background-color: rgba(1,1,1,.1);
                        }
                    }
                    
                    .title{
                        text-transform: uppercase;
                        color:$cyan;
                        font-weight: bold;
                        font-size: 1.5rem;
                        margin-top: 1rem;
                        margin-bottom: 0;
                    }
                    .description{
                        font-size: 1rem;
                    }
                   
                }
            }
        }
    }
    &.product-menu-active {
        .site-content-menu-slide {
            left: 240px;
        }
        .product_container .menu {
            left: 0px;
            padding-right: 0px;
        }
        #product-menu {
            span {
                &.one {
                    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
                    transform: rotate(-45deg) translate(-6px, 6px);
                }
                &.two {
                    opacity: 0;
                }
                &.three {
                     -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
                    transform: rotate(45deg) translate(-6px, -6px) ;
                }
            }
        }

    }
}

#product-menu {
    display: none;
    overflow-x: hidden;
    cursor: pointer;
    border-bottom: 1px solid $grey;
    .icon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        height: 30px;
        z-index: 100;
        &  > span {
            display: block;
            width: 100%;
            height: 2px;
            border-radius: 2px;
            background-color: $cyan;
            margin: 6px 0;
            transition: all 0.5s ease;
        }
    }
    h3 {
        display: inline-block;
        text-transform: uppercase;
        vertical-align: middle;
        font-size: 1.5em;
        margin: 10px;
    }
}

@media only screen and (max-width:960px) {
/* product menu fixed */
    #product-menu {
        display: block;
    }
    .t-products .product_container {
        display: block;
        .menu {
            position: fixed;
            background-color: $off-white;
            width: 240px;
            height: 100%;
            top: 0;
            left: -240px;
            overflow-y: scroll;
            z-index: 1000;
            .title {
                font-size: 1rem;
                padding-left: 10px;
                background-color: #FFF;
                padding-bottom: 0.6rem;
                border-bottom: 1px solid #000;
            }
            .collapsible {
                > ul li {
                    font-size: 16px;
                }
                &.active { /* push down */
                    order: 1;
                }
            }
        }
        .details {
            width: 100%;
            .header {
                margin-bottom: 0px;
            }
        }
    }
}
@media only screen and (max-width: 500px){
    .t-products .product_container .details .body {
        grid-template-columns: 1fr;
        .product-item {
            text-align: center;
        }
    }
}
