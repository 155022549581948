@use 'vars' as *;

.banners {
    font-weight: bold;
    .banner-stage {
        position: relative;
    }
    .banner {
        text-decoration: none;
        text-transform: uppercase;
    }
    .button { /* colours overridden below */
        margin-top: 2rem;
        border: 1px solid $white;
        background-color: transparent;
        color: $white;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
            outline: 1px solid $white;
        }
    }
}
/** absolute banners ***/
.banners-home,
.banners-home_content,
.banners-special_content {
    .banner {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
    }
}
.banner-navigation{
    position: relative;
    display: none; /* show with js */
    text-align: center;
    .banner-navigation-body {
        display: flex;
        justify-content: center;
    }
    .banner-nav-item {
        cursor: pointer;
        border:1px solid $cyan;
        width:15px;
        height:15px;
        border-radius: 15px;
        display: inline-block;
        margin: 0 .5rem;
        > span {
            display: none;
        }
        &.active{
            background-color:$cyan;
        }
    }
}

/** specials banner **/
@mixin skew_div {
    content: "";
    position: absolute;
    display: block;
    top: 0px;
    width: 80%; /* keep skew off the edge */
    height: 100%;
    transform: skew(-30deg);
}
.banners-home_content,
.banners-special_content {
    position: relative;
    width:100%;
    height: auto;
    background-color: $off-white;
    padding: 4rem;
    padding-bottom: 2.5rem;
    overflow: hidden;
    &::before {
        @include skew_div;
        right: 60%; /* keep the edge in center */
        background-color: $blue;
    }
    .banner-stage {
        padding-bottom: 2rem;
        box-sizing: border-box;
    }
    .banner {
        /* absolute */
        bottom: 2rem;
        width: 100%;
        height: auto;
        opacity: 0;
        z-index: 0;
        transition: opacity 0.2s ease;
        &.active{
            position: relative;
            opacity: 1;
            z-index: 1;
        }
    }
    .banner-content {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        background-color:$white;
        min-height: 500px;
        max-width: 1200px;
        margin:0 auto;
        padding: 2rem 4rem;
        box-shadow: $drop-shadow;
        overflow: hidden;
        &::before {
            @include skew_div;
            left: 60%; /* keep the edge in the center */
            background-color: $orange;
        }
    }
    .banner-inner-image{
        position: relative;
        width: 600px;
        max-width: 40%;
        object-fit: contain;
    }
    .banner-content-wrapper{
        position: relative; /* over cyan skew */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; /* left */
        font-weight: bold;
        padding-right: 4rem;
    }
    .banner-name{
        color: $orange;
        font-size: 4rem;
        margin: 0px;
    }
    .banner-title {
        display: none;
    }
    .banner-caption {
        order:-1;
        letter-spacing: 0;
        color: $orange;
        font-size: 2rem;
    }
   .button{
       color: $cyan;
       border-color: $cyan;
       &:hover {
            outline-color: $cyan;
       }
   }
}
.banners-special_content {
    .banner-content::before {
        background-color: $cyan;
    }
}


/*** shared top titles ***/
.banner,
.content-header {
    font-weight: bold;
    text-transform: uppercase;
}
.banner-name,
.overlay .title,
.page-title {
    font-size: 50px;
    line-height: 1;
}
.banner-name,
.overlay .title {
    color: #FFF;
    margin-bottom: 20px;
}
.page-title { /* in content-body */
    color: $cyan;
    font-weight: bold;
    text-transform: uppercase;
}
.banners-home .banner-caption,
.content-header .overlay .summary {
    display: block;
    color: $cyan;
    width: 400px;
    max-width: 100%;
    margin-left: auto;
}

/* home top */
@mixin banner_bg {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: url('../assets/overlay-blue.svg') no-repeat;
    background-size: cover;
    background-position: bottom left;
}
@mixin overlay_bounds {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
    height: auto;
    width: 45%;
    min-height: 32vw;
    margin-left: auto;
    overflow: hidden;
    padding: 3vw 8vw;
    padding-left: 10vw;
}
@mixin overlay_content {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 1rem;
    z-index: 1;
}
.banners-home {
    .banner-stage {
        padding-top: 33%;
    }
    .banner-content-wrapper {
        @include overlay_bounds;
        position: absolute;
        right: 0;
        bottom:0;
        min-height: 100%;

        > span {
            position: relative;
            z-index: 1;
        }
        &:before {
            @include banner_bg;
        }
    }
    .banner {
        /* absolute */
        width: 100%;
        height: 100%;
        background-color: $grey;
        background-position:center;
        background-size:cover;
        opacity: 0;
        transition: opacity 1s ease;
        pointer-events: none;
        &.active {
            opacity: 1;
            pointer-events:auto;
        }
    }
    .banner-navigation {
        display: none;
    }
}

/** content 'banner' **/
.content-header {
    position: relative;
    .overlay-wrapper { /* text set height */
        @include overlay_bounds;
        &:before { /* so we can give it opacity later */
            @include banner_bg;
        }
    }
    .overlay {
        @include overlay_content;
    }
}
.gallery {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    img.image-only {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
    .kmsgallery.display-rotate {
        position: relative;
        width: 100%;
        height: 100%;
        .kmsgallery-images {
            height: 100%;
            padding-top: 0px;
            box-sizing: border-box;
        }
    }
}
.kmsgallery-nav {
    display: none;
}

/*** cta ***/
.banners-cta {
    overflow: hidden;
    padding-top: 3rem;
    padding-bottom: 4rem;

    .banner-content{
        transform: skew(-30deg);
        overflow: hidden;
        position: relative;
        border-bottom-left-radius: 50px;
        border-top-right-radius: 50px;
        width: 20vw;
        margin: 0 2vw;
        & > * {
            transform: skew(30deg);
        }
    }
    .banner-stage{
        display: flex;
        justify-content: space-evenly;
        margin: 0 -10vw;
    }
    .banner-name,
    .banner-caption {
        display: none;
    }
    .banner {
        position: static;
        &:first-of-type,
        &:last-of-type {
            .banner-title { /* gets cut off */
                color: transparent;
            }
            &:hover { /* no hover for you */
                .banner-title {
                    color: transparent;
                }
            }
        }
        &:hover {
            .banner-inner-image {
                filter: brightness(80%);
            }
            .banner-title {
                color: $blue;
            }
        }
    }
    .banner-inner-image {
        width:150%;
        max-width: none;
        margin-left: -5vw;
        transition: filter 0.3s ease;
    }
    .banner-title {
        margin-left:8vw;
        white-space: nowrap;
        font-size: 1.8vw;
        color: $cyan;
        line-height: 3;
        transition: color 0.3s ease;
    }
}


/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (min-width: 2000px){
/** home top + content-header **/
    .banner-home .banner-name,
    .overlay .title {
        font-size: 3vw;
    }
    .banners-home .banner-caption,
    .content-header .overlay .summary {
        font-size: 1vw;
        width: 90%;
    }

    .content-header .overlay-wrapper {
        min-height: 25vw;
    }
}

@media only screen and (max-width: 1200px) {
/** shared titles **/
    .banner-name,
    .overlay .title,
    .page-title {
        font-size: 2em;
    }
/** top banner **/
    .banners-home .banner-content-wrapper,
    .content-header .overlay-wrapper {
        padding-right: 30px;
    }
/** promos **/
    .banners-home_content,
    .banners-special_content {
        padding: 50px 40px;
        padding-bottom: 30px;
        .banner-name {
            font-size: 3rem;
        }
        .banner-content {
            min-height: 400px;
        }
        .banner-content-wrapper {
            padding-right: 10px;
        }
    }
}

@media only screen and (max-width: 960px) {
/** titles the same **/
    .banners-home,
    .banners-home_content, /* promo card style */
    .banners-special_content,
    .content-body .page-title {
        .banner-name {
            font-size: 2em;
        }
    }
    .content-header {
        .overlay {
            .title {
                font-size: 2em;
            }
        }
    }
    .content-body .page-title {
        font-size: 2em;
    }
/** home top banner + content top **/
    .banners-home .banner-caption,
    .content-header .overlay .summary {
        font-size: 16px;
    }
    .banners-home .banner-content-wrapper,
    .content-header .overlay-wrapper {
        width: 60%;
    }
    .banners-home {
        .banner-stage {
            padding-top: 0px;
        }
        .banner {
            min-height: 320px;
            &.active {
                position: relative;
            }
        }
    }
    .content-header .overlay-wrapper {
        min-height: 300px;
    }
    /** content headers **/
    .content-header {
        .overlay-wrapper {

        }
    }
/** promo specials **/
    .banners-home_content,
    .banners-special_content {
        .banner-content {
            padding: 20px 30px;
            min-height: 300px;
        }
        .banner-caption {
            font-size: 1.5rem;
        }
    }
/** cta ***/
    .banners-cta .banner-content{
        border-top-right-radius:35px;
        border-bottom-left-radius:35px;
    }
}
@media only screen and (max-width: 760px) {
/** home top + content top **/
    .banners-home .banner-caption,
    .content-header .overlay .summary {
        color: #FFF;
        width: 100%;
        font-size: 12px;
    }
    /* home top - full */
    .banners-home .banner-content-wrapper,
    .content-header .overlay-wrapper {
        width: 95%;
        background: -moz-linear-gradient(left,  rgba($blue, 0) 0%, rgba($blue, 0.5) 30%, rgba($blue, 1) 80%, rgba($blue, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba($blue, 0) 0%, rgba($blue, 0.5) 30%, rgba($blue, 1) 80%, rgba($blue, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba($blue, 0) 0%, rgba($blue, 0.5) 30%, rgba($blue, 1) 80%, rgba($blue, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        &:before {
            opacity: 0.4;
        }
    }
    .banners-home {
        .banner {
            min-height: 300px;
        }
        .banner-name {
            padding-left: 40px;
        }
        .banner-caption {
            padding-left: 30px;
        }
    }
    .content-header  {
        .overlay-wrapper {
            min-height: 250px;
        }
        .overlay {
            .title {
                padding-left: 40px;
            }
            .summary {
                padding-left: 30px;
            }
        }

    }
/** promos **/
    .banners-home_content,
    .banners-special_content {
        .banner-content:before {
            left: 70%;
        }
        .banner-content-wrapper {
            width: 70%;
            padding-right: 0px;
        }
        .banner-caption,
        .banner-name {
            color: $blue;
        }
        .button {
            background-color: $off-white;
        }
        .banner-inner-image {
            width: 180px;
            max-width: 20%;
        }
    }
    /* allow for overlap */
    .banners-special_content {
        .button {
            color: $orange;
            border-color: $orange;
            &:hover {
                outline-color: $orange;
            }
        }
    }
/** cta **/
    /** cta **/
    .banners-cta  {
        .banner-content {
            border-top-right-radius:25px;
            border-bottom-left-radius:25px;
            width: 27vw;
        }
        .banner-title {
            font-size: .8rem;
        }
    }
}

@media only screen and (max-width: 500px) {
/** top banners **/
    .banners-home .banner-name,
    .content-header .overlay .title {
        font-size: 1.5em;
    }
    .banners-home .button {
        font-size: 12px;
    }
/** promo **/
    .banners-home_content,
    .banners-special_content  {
        padding: 20px;
        padding-top: 30px;
        &:before {
            right: 80%;
        }
        .banner-stage {
            padding-bottom: 20px;
        }
        .banner-content {
            flex-direction: column;
            align-items: center;
            &:before {
                left: 96%;
            }
        }
        .banner-inner-image {
            max-width: 90%;
        }
        .banner-content-wrapper {
            width: 100%;
            text-align: center;
            align-items: center;
        }
        .button {
            padding: 9px;
            width: 100%;
            min-width: 0px;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
/* stack cta */
    .banners-cta {
        padding: 30px 5px;
        padding-bottom: 40px;
        .banner-stage {
            flex-wrap: wrap;
            justify-content: center;
            margin: 0px;
        }
        .banner {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-between;
            vertical-align: bottom;
            width: 30%;
            padding: 2%;
            margin: 1%;
            &:first-of-type, /* rm placeholders */
            &:last-of-type {
                display: none;
            }
        }
        .banner-content {
            transform: skew(0);
            width: 100%;
            margin: 0px;
            > * {
                transform: skew(0);
            }
        }
        .banner-title {
            font-size: 1em;
            line-height: 1;
            margin-left: 0px;
            white-space: normal;
            padding-bottom: 1%;
        }
    }
}

@media only screen and (max-width: 400px){
    .banners-cta {
        .banner {
            width: 100%;
            padding: 0px 20px;
            margin: 15px 0px;
        }
        .banner-inner-image {
            width: 100%;
            margin-left: 0px;
        }
        .banner-title {
            font-size: 1.5em;
            text-align: center;
        }
    }
}
@media only screen and (max-width: 300px){
/** top banners **/
    .banners-home .banner-content-wrapper,
    .content-header .overlay-wrapper {
        width: 100%;
        padding: 20px;
        text-align: center;
        align-items: center;
        background: rgba($blue, 0.5);
        &:before {
            display: none;
        }
    }
    .banners-home {
        .banner-name,
        .banner-caption {
            padding-left: 0px;
        }
    }
/** promos **/
    .banners-home_content,
    .banners-special_content {
        &:before {
            right: 100%;
        }
        .banner-content:before {
            left: 110%;
        }
    }
}