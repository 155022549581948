@use 'vars' as *;

body.t-contact{
    font-size:1rem;

    /*============ Contact ============*/
    .contact-details.has-map,
    .map-wrap {
        display: inline-block;
        vertical-align: bottom;
        width: 48%;
        box-sizing: border-box;
    }
    .contact-details.has-map {
        padding-right: 10px;
    }

    .contact-details p {
        margin: 5px 0;
    }
    .opening-hours {
        display: block;
    }
    .contact-wrapper form {
        padding-top: 20px;
    }
    .contact-wrapper form p {
        margin: 10px 0;
    }
    #map, #map-click {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    #map {
        display: block;
    }
    .map-wrap {
        position: relative;
        background-color: #d3d3d3;
        padding-top: 280px;
    }

    .contact-details-wrapper{
        display: flex;
        color:$grey-text;
        & > .contact-details{
            padding:1rem 4rem;
            max-width: 25rem;
            .title{
                color:$cyan;
                font-size: 1.5rem;
                font-weight: bold;
                text-transform: uppercase;
                margin:0;
                margin-bottom: 1rem;
            }
            .footer-address{
                margin:1rem 0;
            }
            @mixin site-days-style{
                min-width: 100px;
                display: inline-block;
            }
            .opening-days{
                & > .opening-day{
                    @include site-days-style();
                }
            }
            .closed-day{
                @include site-days-style();
            }
        }
       
    }
    .contact-form-wrapper{
        text-align: center;
        margin-top:4rem;
        .contact-form{
            margin-bottom:4rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap:1rem;
            color: $grey-text;
            & > *:last-child{
                grid-column: 1/-1;
            }
            .sub-grid{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 1rem;
            }
            input, textarea{
                background-color: $off-white!important;
                color:$black!important;
                border:none;
                outline:none;
                padding-left:10px;
            }
            label{
                padding-left:10px;
                padding-top:3px;
            }
            select{
                width: 100%;
                height: 40px;
                padding-left: 6px;
                letter-spacing: 1px;
                background-color: #F1F1F1 !important;
                &>:first-child {
                    color:$grey-text;
                }
                &>:nth-child(n + 2) {
                    color:$black;
                }
            }
            textarea{
                height: 10rem;
            }
        }
        button{
            @include button;
        }
        hr{
            margin-top: 4rem;
        }
       
        
    }
    /*** Form Response ***/
    #form-result{
        text-align: center;
        margin-top: 4rem;
        .title{
            color:$cyan;
        }
    }
    @media screen and (max-width: 760px){
        .content-body{
            padding:0;
            .contact-details-wrapper{
                flex-direction: column;
                .contact-details{
                    padding:2rem 0;
                    padding-bottom: 0;
                }
            }
             /** form **/
             .contact-details.has-map,
             .map-wrap{
                 width: 100%;
                 padding: 0px;
             }
             .map-wrap {
                 padding-top: 60%;
                 min-height: 200px;
                 box-sizing: border-box;
             }
             #map-click {
                 display: block;
             }
        }
    
    }
    @media only screen and (max-width: 600px) {
        .contact-form-wrapper{
            .contact-form{
                margin-bottom: 20px;
                grid-template-columns: 1fr;
            }
            button{
                width:100%;
            }
        }
    }
}

@media only screen and (max-width: 300px){
    body.t-contact .contact-form-wrapper .contact-form .sub-grid {
        grid-template-columns: 1fr;
    }
}
